import { Home } from "../containers";

export const routes = [
  {
    title: "Home",
    path: "/",
    component: <Home route={""} />,
    protected: false,
  },
];
