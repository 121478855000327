import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { routes } from "./routes";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import ScrollTop from "./helpers/ScrollTop";
import useFontFaceObserver from "use-font-face-observer";
import { useEffect } from "react";

export const history = createBrowserHistory();

const App = () => {
  const isFontLoaded = useFontFaceObserver([
    { family: "Bitstream Iowan Old Style BT" },
    { family: "Poppins" },
  ]);

  useEffect(() => {
    console.log("Is font loaded?", isFontLoaded);
  }, [isFontLoaded]);
  return (
    <>
      {isFontLoaded ? (
        <>
          <BrowserRouter>
            <ScrollTop />

            <Routes>
              {routes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.protected ? (
                        <ProtectedRoute>{route.component}</ProtectedRoute>
                      ) : (
                        route.component
                      )
                    }
                  />
                );
              })}
            </Routes>
          </BrowserRouter>
        </>
      ) : null}
    </>
  );
};

export default App;
