import style from "./home.module.scss";
import SplashDesktopLeft from "../../assets/img/home/splash-desktop-left.png";
import SplashDesktopRight from "../../assets/img/home/splash-desktop-right.png";
import WaterSplash from "../../assets/img/home/water-splash.mp4";
import { Formik, Form, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import useWindowDimensions from "../../helpers/windowWidth";
import { useState, useEffect, useRef } from "react";
import axios from "axios";

const FormCard = ({ width }) => {
  const ContactSchema = Yup.object().shape({
    email: Yup.string().email(`Lütfen email giriniz`).required(`Zorunlu alan.`),
  });
  const [success, setSuccess] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const [isError, setError] = useState(false);
  const formRef = useRef();

  const createRecord = (email) => {
    setLoad(true);
    axios
      .post(
        `https://kibele-yarman-backend.herokuapp.com/website/email-create`,
        {
          email: email,
        }
      )
      .then((res) => {
        formRef.current.resetForm();
        setSuccess(true);
      })
      .catch((err) => {
        formRef.current.resetForm();
        setLoad(false);
        setError(true);
      });
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ContactSchema}
      onSubmit={(values) => {
        // createPayment(values);
        setError(false);
        createRecord(values.email);
      }}
      innerRef={formRef}
    >
      {({ errors, touched, values, handleSubmit }) => (
        <Form
          className={`${style.form} ${success ? style.success : null}`}
          id="payment"
          onSubmit={handleSubmit}
        >
          <div
            className={`${style.inputWrapper} ${
              (errors.email && touched.email) || isError ? style.error : null
            }`}
          >
            <Field
              type={"email"}
              id={"email"}
              name={"email"}
              disabled={success}
              placeholder={
                success
                  ? "E-Mail adresin başarıyla kaydedildi."
                  : "mail adresi giriniz"
              }
              value={values.email}
            />
            {/* {errors.email && touched.email ? <span>{errors.email}</span> : null} */}
          </div>
          <button
            disabled={success || isLoad}
            type="submit"
            className={`${style.button} `}
          >
            {success ? null : isLoad ? (
              "KAYIT EDİLİYOR..."
            ) : width > 940 ? (
              "BANA HATIRLAT"
            ) : (
              <>
                BANA
                <br />
                HATIRLAT
              </>
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

const CountDown = () => {
  const initalDate = "2024-12-21T00:00:00";
  const [eventDate, setEventDate] = useState(new Date(initalDate));
  const [timeRemaining, setTimeRemaining] = useState(() => {
    // İlk hesaplama
    const currentTime = new Date().getTime();
    const eventTime = new Date(initalDate).getTime();
    return eventTime - currentTime;
  });

  useEffect(() => {
    if (eventDate) {
      const countdownInterval = setInterval(() => {
        const currentTime = new Date().getTime();
        const eventTime = new Date(eventDate).getTime();
        let remainingTime = eventTime - currentTime;

        if (remainingTime <= 0) {
          remainingTime = 0;
          clearInterval(countdownInterval);
        }

        setTimeRemaining(remainingTime);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [eventDate, timeRemaining]);

  const formatTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return {
      day: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  };

  //   console.log(formatTime(timeRemaining).seconds);

  return (
    <div className={style.countdown}>
      <>
        {timeRemaining ? (
          <>
            <div className={style.card}>
              <p className={style.date}>{formatTime(timeRemaining).day}</p>
              <p className={style.dateTitle}>Gün</p>
            </div>
            <div className={style.card}>
              <p className={style.date}>{formatTime(timeRemaining).hours}</p>
              <p className={style.dateTitle}>Saat</p>
            </div>
            <div className={style.card}>
              <p className={style.date}>{formatTime(timeRemaining).minutes}</p>
              <p className={style.dateTitle}>Dakika</p>
            </div>
            <div className={style.card}>
              <p className={style.date}>{formatTime(timeRemaining).seconds}</p>
              <p className={style.dateTitle}>Saniye</p>
            </div>
          </>
        ) : null}
      </>
    </div>
  );
};

const Home = () => {
  const { width } = useWindowDimensions();
  return (
    <div className={style.wrapper}>
      {/* <img className={style.splashLeftDesktop} src={SplashDesktopLeft} /> */}
      {/* <img className={style.splashRightDesktop} src={SplashDesktopRight} /> */}

      <video playsInline muted autoPlay loop>
        <source src={WaterSplash} type="video/mp4" />
      </video>
      <div className={style.content}>
        {width > 940 ? (
          <h1 className={style.title}>ÖLÜNCE BENİ KİM YIKAYACAK?</h1>
        ) : (
          <h1 className={style.title}>
            ÖLÜNCE
            <br /> BENİ
            <br /> KİM
            <br /> YIKAYACAK?
          </h1>
        )}
        <CountDown />
        <h5 className={style.subTitle}>21 ARALIK’I BEKLEYİN</h5>
        <FormCard width={width} />
      </div>
    </div>
  );
};

export default Home;
